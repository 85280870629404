import React from 'react';
import PropTypes from 'prop-types';

import {
    container,
    messageContainer,
} from './style.module.css';

const anim_timeout = 500;

/**
 * Message after form submit
 * 
 * @param {Boolean} show    is message shows
 */
const SendMessage = ({
    show = false,
}) => {
    const modalRef = React.useRef();

    /**
     * Message show work
     */
    React.useEffect(() => {
        const modal = modalRef.current;
        const body = document.getElementsByTagName('body')[0];
        
        // show
        if (show) {
            modal.style.display = 'flex';
            body.style.overflow = 'hidden';

            setTimeout(() => {
                if (modal) modal.style.opacity = '1';
            }, 4);
        // hide
        } else {
            modal.style.opacity = '';
            
            setTimeout(() => {
                if (modal) modal.style.display = '';
                if (body) body.style.overflow = '';
            }, anim_timeout);
        }
    }, [show])

    return (
        <div className={`${container} flex`} ref={modalRef}>
            <div className={`${messageContainer} sm:mx-[8px] m-auto rounded-large`}>
                <p className={`text-base font-bold text-center`}>
                    Ура, сообщение отправлено!<br/>
                    Мы свяжемся с вами!
                </p>
            </div>
        </div>
    )
}

SendMessage.propTypes = {
    show: PropTypes.bool,
}

export default SendMessage;