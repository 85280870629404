import React from "react"
import { graphql } from 'gatsby'

import LayoutContainer from "../containers/LayoutContainer"
import Home from "../components/Main/Home"

import menu from '../data/Home/ru.menu.json';
import footer_data from '../data/ru.footer.json';

// request image data to the `gatsby` for `gatsby-image` from `graphQL`
export const pageQuery = graphql`fragment fluidImage_withWebp_noBase64 on File {
  childImageSharp {
    gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
  }
}

{
  brake_system: file(
    relativePath: {eq: "car-elements-to-check/brake-system.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  car_body: file(
    relativePath: {eq: "car-elements-to-check/car-body.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  computer_diagnostics: file(
    relativePath: {eq: "car-elements-to-check/computer-diagnostics.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  databases: file(
    relativePath: {eq: "car-elements-to-check/databases.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  electrical_components: file(
    relativePath: {eq: "car-elements-to-check/electrical-components.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  engine: file(
    relativePath: {eq: "car-elements-to-check/engine.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  exhaust_system: file(
    relativePath: {eq: "car-elements-to-check/exhaust-system.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  interior: file(
    relativePath: {eq: "car-elements-to-check/interior.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  running_gear: file(
    relativePath: {eq: "car-elements-to-check/running-gear.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  safety_systems: file(
    relativePath: {eq: "car-elements-to-check/safety-systems.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  steering: file(
    relativePath: {eq: "car-elements-to-check/steering.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  test_drive: file(
    relativePath: {eq: "car-elements-to-check/test-drive.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  tire: file(
    relativePath: {eq: "car-elements-to-check/tire.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  transmission: file(
    relativePath: {eq: "car-elements-to-check/transmission.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
  turbocharger: file(
    relativePath: {eq: "car-elements-to-check/turbocharger.png"}
  ) {
    ...fluidImage_withWebp_noBase64
  }
}
`;

/**
 * Home page
 * 
 * @param {Object} data   graphQL data
 */
const IndexPage = ({
  data
}) => {
  const lang = 'ru';
  
  return (
    <LayoutContainer
      menu_data={menu.menu}
      footer_data={footer_data.menu}
      lang={lang}>
      <Home
        image_data={data}
        lang={lang} />
    </LayoutContainer>
  )
}

export default IndexPage
