import React from 'react';
// import { navigate } from 'gatsby';
import { useForm } from '@formspree/react';

import SendMessage from './SendMessage';

import {
    form,
    form__input,
    form__submit,
} from './style.module.css';

// encode symbols for URI
// const encode = (data) => {
//     return Object.keys(data)
//       .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//       .join('&')
// }

const message_close_timeout = 3000;

/**
 * Form on home page
 */
const Form = () => {
    const [phone, update_phone] = React.useState('');
    const [show_message, toggle_show_message] = React.useState(false);
    const [state, handleSubmit] = useForm("xwkyqjpb");

    /**
     * Add listener when open form message after form submit and remove
     */
    React.useEffect(() => {
        if (show_message) window.addEventListener('keydown', key_listener);
        else window.removeEventListener('keydown', key_listener);
    }, [show_message])

    /**
     * Key down listener for `esc`
     * 
     * @param {Object} e   keydown data 
     */
    const key_listener = (e) => {
        if (e.key === "Escape") {
            toggle_show_message(false);
            window.removeEventListener('keydown', key_listener);
        }
        
        // after cleaner event listener
        if (show_message === false) window.removeEventListener('keydown', key_listener);
    }

    /**
     * Submit handler
     * 
     * @param {Element} e   form
     */
    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     const form = e.target

    //     fetch('/',
    //         {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //             body: 
    //                 encode({
    //                     'form-name': form.getAttribute('name'),
    //                     phone
    //                 }),
    //         })
    //     // .then(() => navigate(form.getAttribute('action')))
    //     .catch((error) => alert(error))
    // }

    return(
        <div>
            <form className={`${form} mt-[16px] flex flex-col w-full`}
                // method="post"
                // name='contact'
                // action="/"
                onSubmit={e => {
                    handleSubmit(e);
                    update_phone('');
                    toggle_show_message(true);
                    setTimeout(() => {
                        toggle_show_message(false);
                    }, message_close_timeout);
                }}
                // data-netlify="true"
                // data-netlify-honeypot="bot-field"
                >
                {/* <input type="hidden" name="form-name" value="contact" /> */}
                <input
                    className={`${form__input} text-center text-2xl placeholder:text-dark-grey`}
                    type='tel'
                    name='phone'
                    placeholder='+420 601 256 408'
                    pattern="+[0-9]{12}"
                    title='Введите номер в формате "+хххххххххххх" (без пробелов)'
                    value={phone}
                    maxLength={13}
                    onKeyPress={(e) => {
                        if (!isFinite(+e.key) && e.key !== '+') e.preventDefault()
                    }}
                    onChange={(e) => update_phone(e.target.value)} />
                <button
                    type='submit'
                    className={`${form__submit} text-2xl hover:opacity-75 active:opacity-75`}>
                    Связаться
                </button>
            </form>
            <SendMessage show={show_message} />
        </div>
    )
}

export default Form;