import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Seo from '../../seo';
import Slider from './Slider';
import Faq from '../../Common/FAQ';

import { check_list_slash } from '../../../const/list_slash';

import slider from '../../../data/Home/ru.slider.json';
import check_list from '../../../data/Home/ru.check_list.json';
import price from '../../../data/Home/ru.price.json';
import faq from '../../../data/Home/ru.faq.json';

import {
    checkItem,
    checkItem__image,
    checkItem__header,
    checkItem__text,

    priceItem,
    priceItem__image,
    
    formContainer,
} from './style.module.css';
import Form from './Form';

/**
 * Home page
 * 
 * @param {Object} image_data   image data from `graphQL` for `gatsby-image`
 */
const Home = ({
    image_data = null,
}) => (
    <main className='flex flex-col w-full pb-[180px]'>
        <Seo title='Профессиональная проверка и индивидуальный подбор автомобилей в Чехии' />
        <h1 className='text-5xl md:text-4xl sm:text-2xl mt-[32px] font-bold text-center'>Профессиональная проверка и&nbsp;индивидуальный&nbsp;подбор автомобилей в Чехии</h1>
        <div className='rounded-large overflow-hidden mt-[16px]'>
            <Slider slides={slider.slides} />
        </div>
        <p className='text-2xl sm:text-xl mt-[24px] text-center font-medium text-dark-grey'>
            Бесплатно найдём подходящее авто, прокомментируем объявления выбранные вами. Каждый автомобиль детально проверяем. Мы ценим вас и ваше время.
        </p>
        <section className='mt-[96px] border border-light-grey py-[24px] rounded-large'>
            <h2 className='text-4xl md:text-3xl sm:text-xl font-bold text-center'>Внимательно проверяем авто</h2>
            <p className='mt-[16px] text-center font-medium text-dark-grey max-w-textContainer mx-auto'>По пунктам оценим основные элементы автомобиля</p>
            <ul className='flex flex-wrap mt-[24px]'>
                {
                    check_list && check_list.check_list.map((check_item, index) => {
                        const check_item_description_list = check_item.text.split(check_list_slash);

                        return(
                            <li className={`${checkItem} border-r border-r-light-grey border-t border-t-light-grey`} key={index}>
                                {
                                    image_data && image_data[check_item.image+''] ?
                                    <div className={checkItem__image}>
                                        <GatsbyImage 
                                            image={{
                                                    ...image_data[check_item.image+''].childImageSharp.gatsbyImageData,
                                                    sizes: '(min-width: 1400px) 1900px, (min-width: 1000px) 1000px, (min-width: 700px) 700px, (min-width: 300px) 300px',
                                                }}
                                            alt={check_item.header ? check_item.header : 'Список проверки'} />
                                    </div> : null
                                }
                                <p className={`${checkItem__header} font-bold text-xl`}>{check_item.header}</p>
                                {
                                    check_item.text_type === 'list' ?
                                    <ul className={`${checkItem__text} text-xm m-0 list-disc flex flex-col items-center`}>
                                        {
                                            check_item_description_list.map((item, index) => (
                                                <li key={index}>
                                                    <p className={`text-inherit text-xm m-0`}>{item}</p>
                                                </li>
                                            ))
                                        }
                                    </ul> : <p className={`${checkItem__text} text-xm`}>{check_item.text}</p>
                                }
                                
                            </li>
                        )
                    })
                }
            </ul>
        </section>
        <section className='mt-[96px] border border-light-grey pt-[16px] px-[12px] pb-[32px] bg-grey rounded-large flex flex-col' id='prices'>
            <h2 className='text-4xl md:text-3xl sm:text-xl font-bold text-center'>Цены</h2>
            <p className='mt-[16px] text-center font-medium text-dark-grey max-w-textContainer mx-auto text-base'>
                Индивидуальный поиск и подбор автомобиля. Или детальная проверка автомобиля по вашему желанию.
            </p>
            <ul className='flex flex-wrap justify-between mt-[24px]'>
                {
                    price && price.price.map((service, index) => (
                        <li key={index} className={`${priceItem} rounded-large border-2 flex flex-col p-[12px]`}>
                            <div className='flex'>
                                {
                                    service.image ?
                                    <img className={`${priceItem__image} mr-[4px]`} src={service.image} alt={service.header} /> : null
                                }
                                <div className='flex flex-col'>
                                    <p className='font-bold text-lg'>{service.header}</p>
                                    <p className='font-bold text-4xl'>{service.price}</p>
                                </div>
                            </div>
                            <p className='font-medium mt-[4px] text-sm text-dark-grey'>{service.description}</p>
                        </li>
                    ))
                }
            </ul>
            <p className='mt-[24px] text-center font-medium text-dark-grey max-w-textContainer mx-auto text-base'>
                Выезд специалиста до автомобиля в Праге и окрестностях входит в стоимость проверки. Выезд специалиста за пределы Праги оплачивается дополнительно из расчёта 10 кр/км до проверяемого автомобиля
            </p>
            <div className='mt-[24px] flex'>
                <Link to={'/services'} className='text-2xl pt-[7px] pb-[9px] px-[24px] border border-black rounded-large mx-auto default-button'>Смотреть услуги</Link>
            </div>
        </section>
        <div className='mt-[96px] flex'>
            <div className={`${formContainer} border border-light-grey bg-grey rounded-large flex flex-col items-center`}>
                <h2 className='text-4xl md:text-3xl sm:text-xl font-bold text-center'>Будем рады помочь</h2>
                <Form />
                <p className='mt-[16px] font-bold text-base md:text-sm'>Или напишите нам</p>
                <div>
                    <a href='https://wa.me/420601256408' rel='noreferrer'>
                        <img className='mt-[4px] w-[32px] h-[32px]' src={'assets/icons/whatsapp.svg'} alt='WU icon' />
                    </a>
                </div>
            </div>
        </div>
        <Faq data={faq.faq_list} />
    </main>
)

Home.propTypes = {
    image_data: PropTypes.object,
}

export default Home;