import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,
    Autoplay, A11y } from "swiper";
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
    slide__image
} from './style.module.css';

// delay before slide changing
const autoplay_delay = 5000;

/**
 * Slider on home page Карусель на главной странице
 * 
 * @param {Array} slides    slider data
 * 
 * @extends swiper
 */
const Slider = ({
    slides = [],
}) => (
    <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[
            Navigation,
            Autoplay, A11y
        ]}
        grabCursor={true}
        centeredSlides={true}
        navigation
        loop={true}
        className='slider'
        autoplay={{
          delay: autoplay_delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
    >
        {
            slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    {
                        slide.link ?
                        <Link to={slide.link}>
                            <img className={slide__image} src={slide.image} alt={slide.title} />
                        </Link> :
                        <div>
                            <img className={slide__image} src={slide.image} alt={slide.title} />
                        </div>
                    }
                </SwiperSlide>
            ))
        }
    </Swiper>
)

Slider.propTypes = {
    slides: PropTypes.array.isRequired,
}

export default Slider;