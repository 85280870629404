import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    resetNextUuid,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';

 import { FAQ_HEADER } from '../../../const/lang/faq';

/**
 * "FAQ" block
 *
 * @param {Array} data    QA data
 * @param {String} lang   current language
 *
 * @extends react-accessible-accordion
 */
const Faq = ({
    data = [],
    lang = 'ru'
}) => {
    // Reset uuid
    // sometimes when moving from another page block is displayed incorrectly (reset id fixes this)
    resetNextUuid();

    return (
        <div className={`border border-light-grey rounded-large pt-[16px] px-[16px] mt-[96px]`} id='faq'>
            <h2 className='text-4xl md:text-3xl sm:text-xl font-bold text-center'>{FAQ_HEADER[lang]}</h2>
            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                {data.map((faq, index) => (
                    <AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {faq.header}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: faq.text,
                                }}
                            />
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

Faq.propTypes = {
    data: PropTypes.array,
    lang: PropTypes.string
}

export default Faq;
